import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppLayoutComponent } from "./layout/app.layout.component";
import { RoleGuard } from "./shared/services/role.guard";

const routes: Routes = [
  {
      path: "",
      loadChildren: () =>
        import("./landing/landing.module").then((m) => m.LandingModule),
      data: { role: [] },
  },
  {
    path: "",
    component: AppLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        data: { role: [] },
        canActivate: [RoleGuard],
      },
      {
        path: "project",
        loadChildren: () =>
          import("./project/project.module").then((m) => m.ProjectModule),
        data: { role: [2, 3, 4] },
        canActivate: [RoleGuard],
      },
      {
        path: "reconciliation",
        loadChildren: () =>
          import("./reconciliation/reconciliation.module").then(
            (m) => m.ReconciliationModule
          ),
        data: { role: [2, 3, 4] },
        canActivate: [RoleGuard],
      },
      {
        path: "shape",
        loadChildren: () =>
          import("./shape/shape.module").then((m) => m.ShapeModule),
        data: { role: [4] },
        canActivate: [RoleGuard],
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
        data: { role: [3, 4] },
        canActivate: [RoleGuard],
      },
      {
        path: "organization",
        loadChildren: () =>
          import("./org/org.module").then((m) => m.OrgModule),
        data: { role: [1] },
        canActivate: [RoleGuard],
      },
      {
        path: "beam-cutting",
        loadChildren: () =>
          import("./beam-cutting/beam-cutting.module").then((m) => m.BeamCuttingModule),
        data: { role: [ 1, 2, 3] },
        canActivate: [RoleGuard],
      },
    ],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "**", redirectTo: "/"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
